<template>
  <div v-if="currentRoute" class="beirat-fellows">
    <stage :stageLabel="currentRoute.title.rendered" :big="true" :title="currentRoute.title.rendered"/>
    <introduction fontWeight="bold" :subline="currentRoute.acf.beirat_title" :maintext="currentRoute.acf.beirat_text"/>
    <div class="beirat-fellows__content">
        <team-blocks :small="true" :team="currentRoute.acf.beirat"/>
    </div>
    <introduction fontWeight="bold" :subline="currentRoute.acf.fellows_title" :maintext="currentRoute.acf.fellows_text"/>
    <div class="beirat-fellows__content">
        <team-blocks :small="true" :team="currentRoute.acf.fellows"/>
        <div v-if="currentRoute.acf.blocks.length" class="beirat-fellows__section-bg">
            <h3 class="beirat-fellows__sectionhead">{{currentRoute.acf.more}}</h3>
            <inline-blocks :withMargin="false" :blocks="currentRoute.acf.blocks"/>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Stage from '@/components/stage.vue'
import Introduction from '@/components/introduction.vue'
import TeamBlocks from '@/components/team-blocks.vue'
import InlineBlocks from '@/components/inline-blocks.vue'
import {mapGetters, mapActions} from 'vuex'


export default {
  name: 'Beirat-Fellows',
  components: {
    Stage,
    Introduction,
    TeamBlocks,
    InlineBlocks
  },
  computed: {
    ...mapGetters([
        'getFetched',
    ]),
    currentRoute(){
      const getFetched =  this.getFetched[this.$route.name];
      return getFetched;
    },
  },
  created() {
      if(!this.currentRoute) {
          this.fetchCurrentPage(this.$route.path);
      }
  },
  methods: {
    ...mapActions([
          'fetchCurrentPage',
    ]),
  }
}
</script>
<style lang="scss" scoped>
.beirat-fellows {
  margin-top: 86px;
  padding: 0 .8rem;
  &__content {
    max-width: 1140px;
    margin: auto;
    padding:  0 .8rem;
  }
  &__sectionhead {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family:"neue-plak";
    font-weight: 500;
    color: #182952;
    margin-bottom: 65px;
  }
  &__section-bg {
        padding:30px 0;
        background-color: #e8edf8;
        position: relative;
        margin-bottom: 1.6rem;
        &:after {
            content: '';
            position: absolute;
            width: 100vw;
            left: calc(50% - 50vw);
            top: 0;
            z-index: -1;
            height: 100%;
            background-color: #e8edf8;
        }
    }
}
</style>
